

































import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import UserListRow from '@/components/Mobile/UserListRow.vue';
import FullwidthTabs from '@/components/Mobile/FullwidthTabs.vue';
import { mapGetters } from 'vuex';
import RideeUser from '@/dtos/user';

const tabs = [
  { name: 'Folge Ich', id: 0 },
  { name: 'Aktiv', id: 1 },
  { name: 'Alle', id: 2 },
];

export default Vue.extend({
  name: 'users',
  components: { Scaffold, FullwidthTabs, UserListRow },
  props: {
    selectedMenuItem: {
      type: String,
      required: false,
      default: 'users',
    },
    showBack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.loadUsers(true);
  },
  data() {
    return {
      tabs,
      currentTabID: 1,
    };
  },
  methods: {
    tabSelected(tabId: number): void {
      this.currentTabID = tabId;
      let showLoading = false;
      switch (tabId) {
        case 0:
          showLoading = this.followings.length == 0;
          break;
        case 1:
          showLoading = this.active.length == 0;
          break;
        case 2:
          showLoading = this.all.length == 0;
          break;
      }
      this.loadUsers(showLoading);
    },
    sentinelVisibilitiChanged(isVisible: boolean) {
      if (!isVisible) return;
      this.loadUsers();
    },
    async loadUsers(showLoading = false) {
      if (this.loading) return;

      if (showLoading) this.$store.dispatch('toggleLoading', true);

      if (this.currentTabID == 0) {
        await this.$store.dispatch('user/fetchFollowings');
      }

      if (this.currentTabID == 1) {
        await this.$store.dispatch('user/bindActive');
      }

      if (this.currentTabID == 2) {
        await this.$store.dispatch('user/bindAll');
      }

      if (showLoading) this.$store.dispatch('toggleLoading', false);
    },
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
      followings: 'user/getFollowings',
      all: 'user/getAll',
      active: 'user/getActive',
      loading: 'isLoading',
    }),
    users(): RideeUser[] {
      const users = [this.followings, this.active, this.all];
      if (!(this.currentTabID >= 0 && this.currentTabID < users.length))
        return [];
      return users[this.currentTabID].filter(
        (user: RideeUser) => user.id != this.me.data.uid
      );
    },
    fallbackMessage(): string {
      switch (this.currentTabID) {
        case 0:
          return 'Du folgst noch keinem. Klicke auf einen User um ihm zu folgen.';
        default:
          return 'No users';
      }
    },
  },
});
