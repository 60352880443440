























import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      selectedTabId: undefined as number | undefined,
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    onSelect: {
      type: Function,
    },
    defaultTab: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    highlightedTabId(): number {
      return this.selectedTabId || this.defaultTab;
    },
  },
  methods: {
    selectTab(tabId: number) {
      this.selectedTabId = tabId;
      this.onSelect(tabId);
    },
  },
});
